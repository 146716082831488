import { toast } from 'react-toastify';
import moment from 'moment';
import * as actionTypes from '../actionTypes';
// import { employees } from '../../../../mockData.json';
import { http } from '../../http';
import { EmployeeCountAPI, GetSearchPoeple, AddEmployeeAPI, SetOrRemoveRoleAPIConstant, DeleteEmployee, GetUsersToShoutout, GetUserBiometric, SaveUserBiometric, GetRoles, GetCompanyDetails, SendFriendRequest, /*GetUserDetails,*/GetUserDetailsPeoplePage, GetUserRewardDetails, GetCompanyRewardTier, GetCompanyRewards, DeleteWolibaUser, addAndUpdateLocation, addAndUpdateDepartments, deleteDepartmentOrLocation, addBulkUserForLocation, addBulkUserForDepartment, SyncUserDeviceData, RemoveACMEUsers, ConnectedDevices, UserWellnessInterests, userCompanyDetails, UserProfileReward, GetUserConnectedDevices, GetUserShoutOutCount, GetUserInitiativesPeoplePage, GetSearchPeopleAdmin} from '../../constants/apiConstants'
import {getUser} from '../userProfileActions';
import {getCompanyLocation,getCompanyDepartment} from '../registerActions';
import { CompanyIdForACME } from '../../../utils/constants';
import { employees } from '../../../../mockData';
const getListOfEmployees = () => (dispatch) => dispatch({type: actionTypes.GET_EMPLOYEE_LIST, employeeList: employees});

const networkError = (error) => (dispatch) => dispatch({type: actionTypes.NETWORK_ERROR, networkError: error});

const getSortedEmployees = (search, engagement, department, alphabetical) => (dispatch) =>
  dispatch({type: actionTypes.GET_SORTED_EMPLOYEES, search: search, engagement: engagement, department: department, alphabetical: alphabetical});

const getUserBiometricDetails = (userId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetUserBiometric}/${userId}`, {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.SAVE_BIOMETRIC_DETAILS, biometricDetails: res});
    } catch(error) {
      toast.error(error.message);
      window.location.reload();
    }
  }
};

const fetchSearchPeople = (data, cb) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(GetSearchPoeple, data, {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.GET_SEARCH_PEOPLE, data: res.response, pageNumber: data['page_no']});
      cb();
      dispatch(FetchEmployeeCount(data, true))
    } catch(error) {
      toast.error(error.message);
      cb();
      window.location.reload();
    }
  }
};

const fetchSearchPeopleV2 = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(GetSearchPoeple, data, {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.GET_SEARCH_PEOPLE, data: res.response, pageNumber: data['page_no']});
    } catch(error) {
      toast.error(error.message);
      window.location.reload();
    }
  }
};

const getUsersToShoutout = (data, cb) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(GetUsersToShoutout, data, {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.GET_SEARCH_PEOPLE, data: res.response});
      cb();
    } catch(error) {
      toast.error(error.message);
      cb();
      window.location.reload();
    }
  }
};

const PostAddEmployee = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(AddEmployeeAPI, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.invitation_sent_employees && res.invitation_sent_employees.length) {
        if(res.invitation_sent_employees.length > 0) {
          toast.success(res.message);
          window.location.reload();
        }
        else {
          toast.error(res.message);
        }
        if(res.already_registered_employees.length + res.invalid_email_employees.length > 0) {
          let arr = [];
          for(let i=0; i<res.already_registered_employees.length;i++) {
            let obj = {};
            obj.email = res.already_registered_employees[i];
            arr.push(obj);
          }
          for(let i=0; i<res.invalid_email_employees.length;i++) {
            let obj = {};
            obj.email = res.invalid_email_employees[i];
            arr.push(obj);
          }
          dispatch({ type: actionTypes.SET_ADD_EMPLOYEE_SUCCESS, data: arr});
        }
      }
      else {
        toast.error(res.message)
      }
    } catch(error) {
      toast.error(error.message);
      window.location.reload();
    }
  }
}

const RemoveCSVData = () => (dispatch) => dispatch({ type: actionTypes.REMOVE_CSV }); 

const FetchSetRemoveAPI = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(SetOrRemoveRoleAPIConstant, data,{headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      }
      else {
        dispatch(getUserRoles(data.uid));
        (data.role === 11) && dispatch({ type: actionTypes.SET_REMOVE_ROLE, status: data.status, uid: data.uid})
        toast.success(res[0]);

      }
    } catch(error) {
      toast.error(error.message);
      window.location.reload();
    }
  }
}

const DeleteEmployeeAPI = (data, history) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch, getState) => {
    try {
      let objAcme = {};
      objAcme['company_id'] = CompanyIdForACME;
      objAcme['uids'] = [data['user_uid']];
      const companyId = getState().peopleHome.setUserDetails.user.company_id;
      let isACMEUser = companyId === CompanyIdForACME?1:0;
      let endpoint = isACMEUser ? RemoveACMEUsers : DeleteEmployee;
      let postObject = isACMEUser ? objAcme : data;
      const res = await http.post(endpoint, postObject, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      }
      else {
        toast.success(res[0]);
        if(isACMEUser){
          history.push('/company/people')
        }
        else{
          dispatch({ type: actionTypes.DELETE_EMPLOYEE, uid: data['user_uid']});
        }
      }
    } catch (error) {
      toast.error(error.message);
      // window.location.reload();
    }
  }
}



const FetchEmployeeCount = (data, bool) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(EmployeeCountAPI, data, {headers: { AUTHTOKEN: AuthToken }});
      if(bool) {
        dispatch({ type: actionTypes.SET_EMPLOYEE_COUNT, count:res.count});
      }
      dispatch({ type: actionTypes.SET_PAGINATION_EMPLOYEE_COUNT, count:res.count});
    } catch(error) {
      toast.error(error.message);
      window.location.reload();
    }
  }
}

const saveBiometricInfo = (biometricInfo, history, profile = 0)  => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(SaveUserBiometric, biometricInfo,{headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        if (res.message) {
          typeof res.message === 'string' ?
            toast.error(res.message) : null
        } else {
          typeof res.message === 'string' ?
            toast.success(res[0]) : null
          dispatch({type: actionTypes.SAVE_USER_BIOMETRIC, biometricInfo: biometricInfo});
          dispatch(getUser());
          history.push(profile ? '/portal' : '/company/people');
        }
      }
    } catch(error) {
      typeof error.message === 'string' ?
        toast.error(error.message) : null
      window.location.reload();
    }
  }
};
const getUserRoles = (userId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetRoles}/${userId}`, {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.USER_ROLES_LIST, userRolesList: res});
    } catch(error) {
      toast.error(error.message);
      // window.location.reload();
    }
  }
};

const getUserDetails = (id) => {  
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try{
      const res = await http.get(`${GetUserDetailsPeoplePage}/${id}`, {headers: {AUTHTOKEN: AuthToken}});
      dispatch({ type: actionTypes.SET_USER_DETAILS,setUserDetails:res});
    }
    catch(error){
      toast.error(error.message);
    }
  }
};

const getUserRewardDetails = (userId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetUserRewardDetails}/${userId}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_USER_REWARDS_DETAILS, userRewardsDetails: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getCompanyRewardTierAPI = (companyID) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyRewardTier}/${companyID}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.COMPANY_REWARDS_TIER, companyRewardsBarValues: res[0] });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getCompanyDetails = (companyId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyDetails}/${companyId}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message){
        toast.error(res.message);
      }else{dispatch({ type: actionTypes.GET_COMPANY_DETAILS, companyDetails: res});
      }
    } catch(error) {
      toast.error(error.message);
      window.location.reload();
    }
  }
};

const updateAmigo = (id) => (dispatch) => dispatch({type: actionTypes.UPDATE_AMIGO, id: id});

const sendFriendRequest = (id, obj) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const sendRequest = {
      invitee_id: id,
      status: 'Request_sent'
    };
    try {
      const res = await http.post(SendFriendRequest, sendRequest, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_SEND_FRIEND_REQUEST, sendRequestError: res.message});
      } else {
        toast.success(res[0]);
        dispatch(fetchSearchPeopleV2(obj))
        dispatch(updateAmigo(id));
        dispatch({type: actionTypes.SEND_FRIEND_REQUEST, requestSent: res[0]});
      }
    } catch(error) {
      dispatch(networkError(error.message));
    }
  }
};

const getCompanyRewards = (userId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyRewards}/${userId}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        let companyPrograms = res.company_programs;
        companyPrograms = companyPrograms.map((data) => {
          data.program_levels = data.program_levels.map((level) => {
            level.end_date = moment(level.end_date).format('ll');
            level.achieved_date = level.achieved_date ? moment(level.achieved_date).format('MMM YYYY') : '';
            level.user_point = level.user_point ? level.user_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
            level.point = level.point ? level.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
            level.prize = level.prize ? level.prize.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
            return level;
          });
          return data;
        });
        res.company_programs = companyPrograms;
        dispatch({ type: actionTypes.GET_COMPANY_REWARDS, companyRewards: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const  deleteUserAPI = (obj, history) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(DeleteWolibaUser,obj,{headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        history.push('/company/people');
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};


const editLocationApi = (data, deleteloc) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      dispatch(deleteLocationAndDepartment(deleteloc, data.company_id));
      const res = await http.post(addAndUpdateLocation, data, {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.ADD_AND_UPDATE_LOCATION, data: res.response});  
      let obj = {};
      obj['company_id'] = data['company_id'];
      obj['name'] = "";
      obj['page_no'] = 1;
      obj['limit'] = 24;    
      if(res.message) {
        // window.location.reload();
        dispatch(fetchPeopleAdminV2(obj));
      } else {
        toast.success(res[0]);
        // window.location.reload();
        dispatch(fetchPeopleAdminV2(obj));
        dispatch(getCompanyLocation(data['company_id'], true));
        dispatch(getCompanyDepartment(data['company_id'], true));
      }
    } catch(error) {
      // window.location.reload();
      dispatch(networkError(error.message));
    }
  }
};

const editDepartmentApi = (data, deletedept) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      dispatch(deleteLocationAndDepartment(deletedept, data.company_id));
      const res = await http.post(addAndUpdateDepartments, data, {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.ADD_AND_UPDATE_DEPARTMENTS, data: res.response});
      let obj = {};
      obj['company_id'] = data['company_id'];
      obj['name'] = "";
      obj['page_no'] = 1;
      obj['limit'] = 24;
      if(res.message) {
        // window.location.reload();
        dispatch(fetchPeopleAdminV2(obj));
      } else {
        toast.success(res[0]);
        dispatch(fetchPeopleAdminV2(obj));
        dispatch(getCompanyLocation(data['company_id'], true));
        dispatch(getCompanyDepartment(data['company_id'], true));
        // window.location.reload();
      }
    } catch(error) {
      // window.location.reload();
      dispatch(networkError(error.message));
    }
  }
};

const deleteLocationAndDepartment = (data, id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(`${deleteDepartmentOrLocation}`,data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        // toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(getCompanyLocation(id, true));
        dispatch(getCompanyDepartment(id, true));
      }
    } catch(error) {
      dispatch(networkError(error.message));
    }
  }
};

const postBulkUserForLocation = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(addBulkUserForLocation, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) { 
        toast.error(res.message); 
      } else {
        toast.success(res[0]);       
      }
    } catch(error) {
      // window.location.reload();
      dispatch(networkError(error.message));
    }
  }
};

const postBulkUserForDepartment = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(addBulkUserForDepartment, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) { 
        toast.error(res.message); 
      } else {
        toast.success(res[0]);
      }
    } catch(error) {
      window.location.reload();
      dispatch(networkError(error.message));
    }
  }
};

const syncUserDeviceData = (data, uid) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(SyncUserDeviceData, data,{headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      }
      else {
        dispatch(getUserDetails(uid));
        dispatch(fetchUserConnectedDevices(uid));
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
}

const fetchConnectedDevices = () => {  
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try{
      const res = await http.get(ConnectedDevices, {headers: {AUTHTOKEN: AuthToken}});
      dispatch({ type: actionTypes.FETCH_USER_CONNECTED_DEVICES,userDevices:res});
    }
    catch(error){
      toast.error(error.message);
    }
  }
};

const fetchWellnessInterest = (uid) => {  
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try{
      const res = await http.get(`${UserWellnessInterests}/${uid}`, {headers: {AUTHTOKEN: AuthToken}});
      dispatch({ type: actionTypes.FETCH_USER_INTEREST,userWellnessInterest:res});
    }
    catch(error){
      toast.error(error.message);
    }
  }
};

const fetchUserCompanyDetails = (uid) => {  
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try{
      const res = await http.get(`${userCompanyDetails}/${uid}`, {headers: {AUTHTOKEN: AuthToken}});
      dispatch({ type: actionTypes.FETCH_USER_COMPANY_DETAILS,userProfileCompany:res});
    }
    catch(error){
      toast.error(error.message);
    }
  }
};

const fetchUserProfileReward = (uid) => {  
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try{
      const res = await http.get(`${UserProfileReward}/${uid}`, {headers: {AUTHTOKEN: AuthToken}});
      dispatch({ type: actionTypes.FETCH_USER_PROFILE_REWARD,userProfileReward:res});
    }
    catch(error){
      toast.error(error.message);
    }
  }
};

const fetchUserConnectedDevices = (uid) => {  
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try{
      const res = await http.get(`${GetUserConnectedDevices}/${uid}`, {headers: {AUTHTOKEN: AuthToken}});
      dispatch({ type: actionTypes.FETCH_USER_DEVICES,userConnectedDevice:res});
    }
    catch(error){
      toast.error(error.message);
    }
  }
};

const fetchUserShoutOutCount = (uid) => {  
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try{
      const res = await http.get(`${GetUserShoutOutCount}/${uid}`, {headers: {AUTHTOKEN: AuthToken}});
      dispatch({ type: actionTypes.FETCH_USER_SHOUT_OUT,shoutOutData:res});
    }
    catch(error){
      toast.error(error.message);
    }
  }
};

const fetchUserInitiativesPeoplePage = (uid) => {  
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try{
      const res = await http.get(`${GetUserInitiativesPeoplePage}/${uid}`, {headers: {AUTHTOKEN: AuthToken}});
      dispatch({ type: actionTypes.FETCH_INITIATIVES_DATA,userInitiativesData:res});
    }
    catch(error){
      toast.error(error.message);
    }
  }
};

const fetchPeopleAdmin = (data, cb) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(GetSearchPeopleAdmin, data, {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.GET_ADMIN_PEOPLE, data: res.response, pageNumber: data['page_no']});
      cb();
      dispatch(FetchEmployeeCount(data, true))
    } catch(error) {
      toast.error(error.message);
      cb();
      window.location.reload();
    }
  }
};

const fetchPeopleAdminV2 = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(GetSearchPeopleAdmin, data, {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.GET_ADMIN_PEOPLE, data: res.response, pageNumber: data['page_no']});
    } catch(error) {
      toast.error(error.message);
      window.location.reload();
    }
  }
};

export { RemoveCSVData, FetchEmployeeCount, DeleteEmployeeAPI, FetchSetRemoveAPI, PostAddEmployee,
  fetchSearchPeople, getListOfEmployees, getSortedEmployees, getUsersToShoutout, getUserBiometricDetails, saveBiometricInfo, getUserRoles, getCompanyDetails, sendFriendRequest, fetchSearchPeopleV2, getUserDetails, getUserRewardDetails, getCompanyRewardTierAPI , getCompanyRewards, deleteUserAPI, editLocationApi, editDepartmentApi, deleteLocationAndDepartment, postBulkUserForLocation, postBulkUserForDepartment, syncUserDeviceData, fetchConnectedDevices, fetchWellnessInterest, fetchUserProfileReward, fetchUserCompanyDetails, fetchUserConnectedDevices, fetchUserShoutOutCount, fetchUserInitiativesPeoplePage, fetchPeopleAdmin };